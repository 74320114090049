<template>
	<div class="box" v-loading.fullscreen.lock="fullscreenLoading">
		<div class="title_box flex">
			<Title title="企业认证" />
			<button class="save" @click="subFn">提交资料</button>
		</div>
		<div class="ct_box">
			<!-- 资源logo V认证 -->
			<div class="flex ct1" style="margin-bottom: 2rem">
				<div class="flex" style="align-items: center; justify-content: space-between">
					<p class="title_p"><span>*</span>营业执照：</p>
					<el-upload v-if="!business" class="avatar-uploader" @submit="uploadFn()" action="/api/common/upload"
						:headers="headerObj" accept=".jpg,.png,.gif" :show-file-list="false" x:limit="1"
						:on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload">
						<i class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="flex upload_img" v-if="business">
						<img :src="business" alt="" style="width: 120px" />
						<!-- 删除图标 -->
						<span class="delete_icon el-icon-delete" @click="beforeMove1"></span>
					</div>
					<p style="margin: 4rem 0 0 2rem">jpg、png、gif</p>
				</div>
			</div>
			<div class="flex ct1" style="margin-bottom: 2rem">
				<div class="flex" style="align-items: center; justify-content: space-between">
					<p class="title_p"><span>*</span>认证公函：</p>
					<el-upload v-if="!logo" class="avatar-uploader" @submit="uploadFn()" action="/api/common/upload"
						:headers="headerObj" accept=".jpg,.png,.gif" :show-file-list="false" x:limit="1"
						:on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload">
						<i class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="flex upload_img" v-if="logo">
						<img :src="logo" alt="" style="width: 120px" />
						<!-- 删除图标 -->
						<span class="delete_icon el-icon-delete" @click="beforeMove2"></span>
					</div>
					<p style="margin: 4rem 0 0 2rem">jpg、png、gif</p>
				</div>
			</div>
			<p class="title2">
				认证声明：认证时需遵守企宣通的相关协议，包括但不限于《合同》、《平台服务协议》、《认证公函》。
			</p>
			<!-- <p class="title3" @click="download">下载认证公函</p> -->
			<a href="/file.docx" target="_blank" class="title3">下载认证公函</a>
		</div>
	</div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
export default {
	components: {
		Title,
	},
	data() {
		return {
			fullscreenLoading: false,
			business: '', // 营业执照
			businessUrl: '',
			logo: '',
			logoUrl: '',
			headerObj: {
				token: this.$user_info.token,
			},
		}
	},
	methods: {
		handleAvatarSuccess1(res, file) {
			console.log(res)
			// this.business = URL.createObjectURL(file.raw)
			this.business = res.data.fullurl
			console.log(this.business)
		},
		handleAvatarSuccess2(res, file) {
			this.logo = res.data.fullurl
			// this.logo = URL.createObjectURL(file.raw)
		},
		beforeMove1() {
			this.$confirm('是否删除', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.business = ''
					this.$message({
						type: 'success',
						message: '已删除',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		beforeMove2() {
			this.$confirm('是否删除', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.logo = ''
					this.$message({
						type: 'success',
						message: '已删除',
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg'
			const isLt2M = file.size / 1024 / 1024 < 2

			if (!isJPG) {
				this.$message.error('上传头像图片只能是 JPG 格式!')
			}
			if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 2MB!')
			}
			return isJPG && isLt2M
		},
		// 提交
		subFn() {
			this.fullscreenLoading = true
			this.curlPost('/api/users/auth/save', {
				business_license: this.business,
				auth_official: this.logo,
			}).then((res) => {
				console.log(res)
				this.fullscreenLoading = false
				if (res.data.code) {
					return this.$message({
						type: 'success',
						message: '上传成功,等待审核',
					})
				}
			})
		},
		// 下载认证公函
		download() {
			// this.curlGetCode('/file.docx').then((res) => {
			// 	if (res.status == 1) {
			// 		// 以下为精髓
			// 		const url = window.URL.createObjectURL(new Blob([res.data]));
			// 		const link = document.createElement('a');
			// 		link.href = url;
			// 		let fileName = "导入模板" //保存到本地的文件名称
			// 		link.setAttribute('download', fileName);
			// 		document.body.appendChild(link);
			// 		link.click();
			// 	}
			// })
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../../scss/Enterprise.scss';
</style>